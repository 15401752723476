<div
    class="card mb-3"
    style="border: 2px solid"
    [ngStyle]="{ 'border-color': orderItem.multipickingTaskIdentifierColor }"
>
    <div class="card-header">
        <div
            class="multi-picking-task-identifier"
            *ngIf="orderItem.multipickingTaskIdentifierChar"
            [ngStyle]="{ 'background-color': orderItem.multipickingTaskIdentifierColor }"
        >
            {{ orderItem.multipickingTaskIdentifierChar }}
        </div>
        <div class="mr-3">{{ orderItem.orderItemName }}</div>
        <i
            class="fa fa-map-marker map-icon"
            *ngIf="orderItem.productLocationMapImageUrl"
            (click)="openProductLocationMap()"
        ></i>
    </div>
    <div class="card-body">
        <div class="row">
            <div
                class="mb-2 col-12"
                *ngIf="orderItem.categoryNameToDisplayTranslationCode"
            >
                <p class="mb-1">
                    <strong>
                        <i class="fa fa-tag"></i>&nbsp;{{
                            orderItem.categoryNameToDisplayTranslationCode | translate
                        }}</strong
                    >
                </p>
                <p *ngIf="orderItem.isOffer" class="mb-1">
                    <strong>
                        <i class="fa fa-percent"></i>&nbsp;{{
                            'collecting.offer-text' | translate
                        }}:
                    </strong>
                    {{ orderItem.offerValidTo | date : 'yyyy-MM-dd' }}
                </p>
                <p *ngIf="orderItem.currentPrice" class="mb-1">
                    <strong>
                        <i class="fa fa-money"></i>&nbsp;{{
                            'collecting.current-price-text' | translate
                        }}:
                    </strong>
                    {{ orderItem.currentPrice | priceround }}
                    {{ 'common.currency' | translate }}
                </p>
                <p *ngIf="orderItem.shelfPositionDisplayNameKey" class="mb-1">
                    <strong>
                        <i class="fa fa-arrow-up"></i>&nbsp;{{
                            'collecting.shelf-position-text' | translate
                        }}:
                    </strong>
                    {{ orderItem.shelfPositionDisplayNameKey | translate }}
                </p>
            </div>
            <div
                *ngIf="
                    orderItem.state === orderItemState.Collected &&
                    orderItem.orderReplacementItem !== null
                "
                class="col-12"
            >
                <p>{{ 'collecting.replacement-order-item' | translate }}</p>
            </div>
            <div class="col-4">
                <a
                    *ngIf="orderItem.itemThumbnailSrc !== null; else noImage"
                    (click)="onImageClick()"
                >
                    <img
                        [src]="orderItem.itemThumbnailSrc"
                        [alt]="orderItem.orderItemName"
                    />
                </a>
                <ng-template #noImage>
                    <div class="placeholder">
                        <span>{{ 'common.no-photo' | translate }}</span>
                    </div>
                </ng-template>
            </div>
            <div class="col-8">
                <app-order-item-details [orderItem]="orderItem"></app-order-item-details>
            </div>
        </div>
        <div
            *ngIf="
                orderItem.state === orderItemState.Collected &&
                orderItem.orderReplacementItem !== null &&
                orderItem.orderReplacementItem.quantityRequestedByUser === 0
            "
            class="col-12 alert alert-danger"
        >
            <p class="m-0">
                {{ 'collecting.not-requested-by-customer' | translate }}
            </p>
        </div>
        <div
            *ngIf="
                orderItem.state === orderItemState.Collected &&
                orderItem.orderReplacementItem !== null
            "
            class="row mt-3"
        >
            <div class="col-12">
                <p>{{ 'collecting.original-order-item' | translate }}</p>
            </div>
            <div class="col-4">
                <a
                    *ngIf="
                        orderItem.orderReplacementItem.itemThumbnailSrc !== null;
                        else noImage
                    "
                    (click)="onImageClick()"
                >
                    <img
                        [src]="orderItem.orderReplacementItem.itemThumbnailSrc"
                        [alt]="orderItem.originalItemName"
                    />
                </a>
                <ng-template #noImage>
                    <div class="placeholder">
                        <span>{{ 'common.no-photo' | translate }}</span>
                    </div>
                </ng-template>
            </div>
            <div class="col-8">
                <table>
                    <tr>
                        <td>{{ orderItem.originalItemName }}</td>
                    </tr>
                    <td class="font-weight-bold">
                        {{ 'collecting.quantity' | translate }}:
                        {{ orderItem.orderedQuantity }}
                        {{ 'collecting.' + orderItem.unit | translate }}
                    </td>
                </table>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="btn-toolbar justify-content-between">
            <div
                class="btn-group btn-group-sm"
                app-items-to-collect-buttons
                *ngIf="orderItem.state === null"
                [canCollectManually]="!states.hasMarkupPayment"
                [orderItem]="orderItem"
                [orderRemovedItemReasons]="orderRemovedItemReasons"
            ></div>
            <div
                class="btn-group btn-group-sm"
                app-collected-items-buttons
                *ngIf="orderItem.state === orderItemState.Collected"
                [orderItem]="orderItem"
            ></div>
            <div
                class="btn-group btn-group-sm"
                app-replaced-items-buttons
                *ngIf="orderItem.state === orderItemState.Replaced"
                [orderItem]="orderItem"
                [canEditManually]="!states.hasMarkupPayment"
                [hasBarcodeReader]="states.isBarcodeReadingEnabled"
            ></div>
            <div
                class="btn-group btn-group-sm"
                app-items-not-found-buttons
                *ngIf="orderItem.state == orderItemState.NotFound"
                [orderItem]="orderItem"
            ></div>
        </div>
    </div>
</div>

<lib-modal
    [title]="'users.product-location-map-title' | translate"
    [componentID]="'product-location-map' + orderItem.orderItemID"
    [bgColorClass]="ColorClass.Info"
>
    <i class="fa fa-arrows zoom-icon" (click)="mapSizeChange()"></i>
    <div>
        <img
            [ngClass]="fullSizeMap ? 'full-size-map' : ''"
            [src]="orderItem.productLocationMapImageUrl"
            alt="Map"
        />
    </div>
</lib-modal>
