<li class="list-group-item">
    <table>
        <caption *ngIf="label !== undefined">
            <span class="badge badge-primary" *ngIf="label !== undefined">
                {{ label }}
            </span>
        </caption>
        <tbody>
            <tr>
                <td class="center" rowspan="4" class="w-50">
                    <app-order-item-state-view
                        [orderItemState]="orderItemState"
                    ></app-order-item-state-view>
                    <img
                        style="width: 100px"
                        [src]="orderReplacementItem.itemThumbnailSrc"
                    />
                </td>
                <td class="text-left pl-1">
                    <strong>{{ 'common.sku' | translate }}:</strong>
                </td>
                <td class="text-left">{{ orderReplacementItem.sku || '-' }}</td>
            </tr>
            <tr>
                <td class="text-left pl-1">
                    <strong>{{ 'common.price' | translate }}:</strong>
                </td>
                <td class="text-left">
                    {{ orderReplacementItem.price | priceround }}
                    {{ 'common.currency' | translate }}
                </td>
            </tr>
            <tr>
                <td class="text-left pl-1">
                    <strong>{{ 'collecting.quantity' | translate }}</strong>
                </td>
                <td class="text-left">{{ orderReplacementItem.quantity | decimal }}</td>
            </tr>
            <tr>
                <td class="text-left pl-1">
                    <strong>{{ 'collecting.collected-quantity' | translate }}</strong>
                </td>
                <td class="text-left">
                    {{ orderReplacementItem.collectedQuantity | decimal }}
                </td>
            </tr>
            <tr>
                <td class="center">
                    <span class="text-small">
                        {{ orderReplacementItem.itemName }}
                    </span>
                </td>
                <td></td>
                <td></td>
            </tr>
        </tbody>
    </table>
</li>
