import { Component, Input, OnInit } from '@angular/core';
import { OrderItem } from 'shared';
import { CollectingStatesService } from 'dm-src/app/modules/collecting/collecting-states.service';

@Component({
    selector: 'app-order-item-details',
    templateUrl: './order-item-details.component.html',
    styleUrls: ['./order-item-details.component.scss'],
})
export class OrderItemDetailsComponent implements OnInit {
    @Input() orderItem: OrderItem;
    @Input() isMainPageCollecting: boolean = true;
    public onlineConsultation = false;

    constructor(public states: CollectingStatesService) {}

    ngOnInit() {
        if (this.states.multiCollectingStates?.length > 1) {
            let multiCollectingState = this.states.multiCollectingStates.find(
                (x) => x.orderID == this.orderItem.orderID
            );
            this.onlineConsultation = multiCollectingState.onlineConsultation;
        } else {
            this.onlineConsultation = this.states.onlineConsultation;
        }
    }
}
