import { IQRFactoryService } from './iqr-factory.service';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_CLIENT_SERVICE_IMPL, IAPIClientService } from 'shared';
import { finalize } from 'rxjs/operators';
import { IonLoaderService } from 'shared';
import { MarkupCheckoutData } from 'projects/shared/src/lib/models/markup-checkout-data';
import { OrderItem } from 'shared';

@Injectable()
export class QRFactoryService implements IQRFactoryService {
    constructor(
        @Inject(API_CLIENT_SERVICE_IMPL) private _apiClient: IAPIClientService,
        private _loaderService: IonLoaderService
    ) {}

    getForCheckout(
        orderQrCode: string,
        isUpdateNeeded: boolean
    ): Observable<HttpResponse<Blob>> {
        return this._apiClient.getBlobWithResponse(
            `qrfactory/getForCheckout/${orderQrCode}/${isUpdateNeeded}`
        );
    }

    getOrderData(orderQrCode: string): Observable<HttpResponse<MarkupCheckoutData>> {
        this._loaderService.showLoader();
        return this._apiClient
            .getWithResponse<MarkupCheckoutData>(`markup/getOrderData/${orderQrCode}`)
            .pipe(finalize(() => this._loaderService.hideLoader()));
    }

    getOrderItems(orderID: number): Observable<HttpResponse<OrderItem[]>> {
        return this._apiClient
            .getWithResponse<OrderItem[]>(
                `markup/getOrderItemList/${orderID}`
            );
    }
}
