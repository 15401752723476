import { Component, Input, OnInit } from '@angular/core';
import { OrderReplacementItem, ColorClass, OrderItemState } from 'shared';

@Component({
    selector: 'app-order-replacement-item-view',
    templateUrl: './order-replacement-item-view.component.html',
    styleUrls: ['./order-replacement-item-view.component.scss'],
})
export class OrderReplacementItemViewComponent {
    public ColorClass = ColorClass;
    @Input() public orderItemState: OrderItemState;
    @Input() public orderReplacementItem: OrderReplacementItem;
    @Input() public color: ColorClass = ColorClass.White;
    @Input() public label: string;
    @Input() public isCollectingTask: boolean;
}
