<lib-modal
    [title]="'collecting.product-identify-title'"
    [componentID]="'product-identify-modal'"
    [bgColorClass]="ColorClass.Success"
>
    <ng-container *ngIf="collectingItemsService.states.multiCollectingStates?.length > 1">
        <h5 class="text-dark">
            {{ 'collecting.select-order-to-add-product-if-new' | translate }}
        </h5>
        <div
            *ngFor="
                let multiCollectingState of collectingItemsService.states
                    .multiCollectingStates
            "
        >
            <div
                class="btn btn-info"
                style="margin: 2px; border-radius: 15px; border-width: 3px"
                [ngClass]="{
                    'border border-success':
                        multiCollectingState.orderID ===
                        collectingItemsService.states.orderID
                }"
                [ngStyle]="{
                    'background-color':
                        multiCollectingState.multipickingTaskIdentifierColor
                }"
                (click)="
                    collectingItemsService.setDefaultStatedToSelectedMultiCollectingState(
                        multiCollectingState.orderID
                    )
                "
            >
                {{ multiCollectingState.multipickingTaskIdentifierChar }} -
                {{ multiCollectingState.customerName }}
            </div>

            <span
                *ngIf="
                    multiCollectingState.orderID === collectingItemsService.states.orderID
                "
                [ngClass]="{
                    'p-2 font-weight-bold text-success':
                        multiCollectingState.orderID ===
                        collectingItemsService.states.orderID
                }"
            >
                <i class="fa fa-arrow-right"></i>
                {{ 'collecting.order-selected' | translate }}
            </span>
        </div>
        <hr />
    </ng-container>
    <p>{{ 'collecting.product-identify-text' | translate }}</p>
    <div class="btn-toolbar justify-content-between">
        <div class="btn-image-design">
            <img src="assets/images/barcode.png" (click)="showBarcodeReaderModal()" />
            <div class="text-center">
                {{ 'common.barcode' | translate }}
            </div>
        </div>
        <div class="btn-image-design">
            <img
                src="assets/images/example_product_barcode.png"
                (click)="showManualSKUCheckModal()"
            />
            <div class="text-center">
                {{ 'collecting.type-sku' | translate }}
            </div>
        </div>
    </div>
</lib-modal>
