import { Component, OnInit } from '@angular/core';
import { CollectingStatesService } from '../collecting-states.service';
import { ProductIdentificationSource } from '../../../../types/product-identification-source';
import { ModalService } from 'shared';
import { CollectingItemsService } from '../collecting-items.service';

@Component({
    selector: 'app-actions-toolbar',
    templateUrl: './actions-toolbar.component.html',
    styleUrls: ['./actions-toolbar.component.scss'],
})
export class ActionsToolbarComponent implements OnInit {
    constructor(
        public states: CollectingStatesService,
        private _collectingItemsService: CollectingItemsService,
        private _modalService: ModalService
    ) {}

    ngOnInit(): void {}

    public showCreateItemModal(): void {
        if (!this.states.isPaymentReserved) {
            this.states.setCreateItemModalVisible(true);
        }
    }

    public showProductIdentifyModal(): void {
        this._collectingItemsService
            .checkIfOrderModificationIsBlocked(this.states.orderID)
            .subscribe((underInspection) => {
                if (!underInspection) {
                    this.states.productIdentificationSource =
                        ProductIdentificationSource.ToPick;
                    this.states.replaceableOrderItem = null;
                    this._modalService.setModalVisibility(true, 'product-identify-modal');
                }
            });
    }

    public hideAll() {
        var collapsedElementIDs = [
            'items-to-pick-body',
            'collected-items-body',
            'replaced-items-body',
            'missing-items-body'];

        collapsedElementIDs.forEach(collapsedElementID => {
            var collapsedElement = document.getElementById(collapsedElementID);
            collapsedElement.style.display = 'none';
        });
    }

    public show(id: string) {
        var body = document.getElementById(id + '-body');
        if (body) {
            body.style.display = 'block';
            var header = document.getElementById(id + '-header');
            if (header) {
                header.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
        }
    }
}
