import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import {
    OrderItem,
    OrderItemState,
    IOrdersService,
    ORDERS_SERVICE_IMPL,
    FlashMessageService,
    prepareOrderItemList,
    prepareOrderItem,
    priceRound,
    AppSettings,
} from 'shared';
import {
    COLLECTING_SERVICE_IMPL,
    ICollectingService,
} from 'dm-src/services/collecting/icollecting.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'shared';
import { OrderItemImage } from 'dm-src/types/order-item-image';
import { CollectingStatesService } from './collecting-states.service';
import { CollectingErrorsService } from './collecting-errors.service';
import { ICreateReplacementItem } from 'shared';
import { ICreateOrderItem } from 'shared';
import { ColorClass } from 'shared';
import { CollectingSharedService } from './collecting-shared.service';
import { OrderRemovedItemReasons } from 'projects/shared/src/lib/types/order-removed-item-reasons';
import {
    IRealtimeClientService,
    REALTIME_CLIENT_IMPL,
} from 'projects/shared/src/lib/services/realtime-client/irealtime-client.service';
import { MultiCollectingState } from 'dm-src/dtos/multi-collecting-state';

@Injectable({
    providedIn: 'root',
})
export class CollectingItemsService {
    private _itemToApprove: BehaviorSubject<OrderItem>;
    private _replacementItemToEdit: BehaviorSubject<OrderItem>;
    private _itemsToPick: BehaviorSubject<OrderItem[]>;
    private _collectedItems: BehaviorSubject<OrderItem[]>;
    private _replacedItems: BehaviorSubject<OrderItem[]>;
    private _notFoundItems: BehaviorSubject<OrderItem[]>;
    private _collectedTotalPrice: BehaviorSubject<number>;
    private _sumDeposit: BehaviorSubject<number>;
    public shownOrderItemImage: OrderItemImage;
    private _orderRemovedItemReasons: BehaviorSubject<OrderRemovedItemReasons[]>;
    timeoutFinishedSubject: Subject<void> = new Subject<void>();

    public collectingHub = AppSettings.get('realtimeUrl') + '/collectinghub';

    get itemToApprove() {
        return this._itemToApprove.asObservable();
    }

    get replacementItemToEdit() {
        return this._replacementItemToEdit.asObservable();
    }

    get itemsToPick() {
        return this._itemsToPick.asObservable();
    }

    get collectedItems() {
        return this._collectedItems.asObservable();
    }

    get replacedItems() {
        return this._replacedItems.asObservable();
    }

    get notFoundItems() {
        return this._notFoundItems.asObservable();
    }

    get collectedTotalPrice(): Observable<number> {
        return this._collectedTotalPrice.asObservable();
    }

    get sumDeposit(): Observable<number> {
        return this._sumDeposit.asObservable();
    }

    get orderRemovedItemReasons() {
        return this._orderRemovedItemReasons.asObservable();
    }

    constructor(
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        @Inject(COLLECTING_SERVICE_IMPL)
        private _collectingService: ICollectingService,
        private _errorsService: CollectingErrorsService,
        private _flashMessageService: FlashMessageService,
        private _translateService: TranslateService,
        private _modalService: ModalService,
        private _sharedService: CollectingSharedService,
        @Inject(REALTIME_CLIENT_IMPL)
        private _realtimeClient: IRealtimeClientService,
        public states: CollectingStatesService
    ) {
        this._itemToApprove = new BehaviorSubject<OrderItem>(null);
        this._replacementItemToEdit = new BehaviorSubject<OrderItem>(null);
        this._itemsToPick = new BehaviorSubject<OrderItem[]>(null);
        this._collectedItems = new BehaviorSubject<OrderItem[]>(null);
        this._replacedItems = new BehaviorSubject<OrderItem[]>(null);
        this._notFoundItems = new BehaviorSubject<OrderItem[]>(null);
        this._collectedTotalPrice = new BehaviorSubject<number>(0);
        this._sumDeposit = new BehaviorSubject<number>(0);
        this.shownOrderItemImage = new OrderItemImage();
        this._orderRemovedItemReasons = new BehaviorSubject<OrderRemovedItemReasons[]>(
            null
        );
    }

    public timeoutFinished() {
        this.collectedItems.pipe(take(1)).subscribe((lists) => {
            lists.forEach((item) => {
                if (
                    item.isReplace === true &&
                    item.orderReplacementItem.quantityRequestedByUser == null
                )
                    item.orderReplacementItem.quantityRequestedByUser = 0;
            });
        });

        this.states.allowCheckout = !this.existItemRejectedByUser();
    }

    public async closeConnection(): Promise<void> {
        return await this._realtimeClient.disconnect(this.collectingHub);
    }

    public establishRealTimeConnections(orderId: string): void {
        this._realtimeClient.initHubConnection(
            'collectinghub',
            AppSettings.get('realtimeUrl')
        );

        this._realtimeClient.connect(this.collectingHub).then(() => {
            try {
                this._realtimeClient.invoke('AddToGroup', this.collectingHub, orderId);
                this._realtimeClient.invoke('RefreshOrder', this.collectingHub, orderId);
            } catch (e) {
                console.log(e);
            }

            this._realtimeClient.on(
                'replacementclicked',
                this.collectingHub,
                (response) => {
                    this.collectedItems.subscribe((lists) => {
                        lists.forEach((item) => {
                            if (
                                item.orderItemID === response[1] &&
                                item.orderReplacementItem
                            ) {
                                item.orderReplacementItem.quantityRequestedByUser =
                                    response[2];
                            }

                            // TODO This at one point need to be fixed, since we accept the replacement.
                            // eg. As a courier at one point you can change replacement
                            //item.orderReplacementItem.isAccepted = response[2]
                        });
                    });
                }
            );
        });

        this._realtimeClient.on('newitemadded', this.collectingHub, (response) => {
            if (this.states.orderID === response[2]) {
                this._ordersService.getOrderItem(response[1]).subscribe((item) => {
                    let list = this._itemsToPick.getValue();
                    list.push(item);
                    this._itemsToPick.next(list);
                    this.setIsCollectionReady();
                });
            }
        });

        this._realtimeClient.on(
            'ordercancelledclicked',
            this.collectingHub,
            (response) => {
                if (this.states.orderID === response[0])
                    console.log('TODO ordercancelledclicked');
            }
        );

        this._realtimeClient.on('timeoutfinished', this.collectingHub, (response) => {
            if (this.states.orderID.toString() === response[0]) {
                this.timeoutFinished();
                this.timeoutFinishedSubject.next();
            }
        });
    }

    public getOrderItems(
        taskID: string,
        orderID: number,
        isMultiCollecting = false
    ): void {
        this.states.resetStates();
        this.states.orderID = orderID;
        this.states.taskID = taskID;
        this._collectingService.getOrderItems(taskID, orderID).subscribe(
            (resp) => {
                const response = resp.body;
                if (!response.isExistingSupplier) {
                    this._modalService.setModalVisibility(true, 'supplier-not-exists');
                    return;
                } else if (response.isInspectingInProgress) {
                    this.states.orderQrCode = response.orderQrCode;
                    this.states.isInspectingInProgress = true;
                    this.states.hasMarkupPayment = true;
                    this.states.isBarcodeReadingEnabled = true;
                    this._modalService.setModalVisibility(true, 'checkout-qr-modal');
                    return;
                } else if (response.isPaymentFinished) {
                    this._modalService.setModalVisibility(
                        true,
                        'payment-is-already-finished'
                    );
                    return;
                }
                this.states.providerID = response.providerID;
                this.states.isMetroRetailerModel = response.isMetroRetailerModel;
                this.states.customerName = response.customerName;
                this.states.customerFirstName = response.customerFirstName;
                this.states.customerLastName = response.customerLastName;
                this.states.orderCode = response.orderCode;
                this.states.originalTotalPrice = response.originalTotalPrice;
                this.states.hasMarkupPayment = response.hasMarkupPayment;
                this.states.isWerwPriceForced = response.isWerwPriceForced;
                this.states.isBarcodeReadingEnabled = response.isBarcodeReadingEnabled;
                this.states.isPaymentReserved = response.isPaymentReserved;
                this.states.isPaymentSucceeded = response.isPaymentSucceeded;
                this.states.isPaymentFinalizationDisabled =
                    response.hasOtherTaskPaymentInProgress;
                this.states.existPreferenceSettings = response.existPreferenceSettings;
                this.states.existReplacementProvision =
                    response.existReplacementProvision;
                this.states.customerCalled = response.customerCalled;
                this.states.showPhoneButton = response.showPhoneButton;
                this.states.usePaperBag = response.usePaperBag;
                this.states.onlineConsultation = response.onlineConsultation;
                this.states.noAskForReplacementItems = response.noAskForReplacementItems;
                this.states.acceptsEverything = response.acceptsEverything;
                this.states.customerPhone = response.customerPhone;
                this.states.customerEmail = response.customerEmail;
                this.states.delivererEmail = response.delivererEmail;
                this.states.collectionDate = response.collectionDate;
                this.states.sendApprovalRequest = this.setSendApprovalRequest();
                this.states.orderQrCode = response.orderQrCode;
                this.sortOrderItems(response.orderItems, isMultiCollecting);
                this._orderRemovedItemReasons.next(response.orderRemovedItemReasons);

                if (!isMultiCollecting) this.states.multiCollectingStates = [];
                let multiCollectingState = {
                    orderID: orderID,
                    orderCode: response.orderCode,
                    taskID: taskID,
                    customerName: response.customerName,
                    customerFirstName: response.customerFirstName,
                    customerLastName: response.customerLastName,
                    customerPhone: response.customerPhone,
                    customerEmail: response.customerEmail,
                    originalTotalPrice: response.originalTotalPrice,
                    existPreferenceSettings: response.existPreferenceSettings,
                    usePaperBag: response.usePaperBag,
                    showPhoneButton: response.showPhoneButton,
                    onlineConsultation: response.onlineConsultation,
                    noAskForReplacementItems: response.noAskForReplacementItems,
                    acceptsEverything: response.acceptsEverything,
                    multipickingTaskIdentifierColor:
                        response.multipickingTaskIdentifierColor,
                    multipickingTaskIdentifierChar:
                        response.multipickingTaskIdentifierChar,
                    customerChat: response?.sendBirdChatChannel?.channelUrl
                        ? true
                        : false,
                    customerChatInfo: response?.sendBirdChatChannel,
                };
                this.states.multiCollectingStates.push(multiCollectingState);
                this.states.multiCollectingStates.sort((a, b) => {
                    return a.multipickingTaskIdentifierChar?.localeCompare(
                        b.multipickingTaskIdentifierChar
                    );
                });

                if (this.states.isPaymentFinalizationDisabled) {
                    this._modalService.setModalVisibility(true, 'payment-in-progress');
                }
            },
            (error) => {
                const callback = () => {
                    this.getOrderItems(taskID, orderID);
                };
                this._errorsService.handlePaymentErrorResponse(
                    error.status,
                    callback.bind(this)
                );
            }
        );
    }

    public setDefaultStatedToSelectedMultiCollectingState(orderId: number): void {
        let selectedMultiCollectingState = this.states.multiCollectingStates.find(
            (state) => state.orderID === orderId
        );
        this.states.orderID = selectedMultiCollectingState.orderID;
        this.states.orderCode = selectedMultiCollectingState.orderCode;
        this.states.taskID = selectedMultiCollectingState.taskID;
        this.states.customerName = selectedMultiCollectingState.customerName;
        this.states.customerFirstName = selectedMultiCollectingState.customerFirstName;
        this.states.customerLastName = selectedMultiCollectingState.customerLastName;
        this.states.customerPhone = selectedMultiCollectingState.customerPhone;
        this.states.customerEmail = selectedMultiCollectingState.customerEmail;
        this.states.originalTotalPrice = selectedMultiCollectingState.originalTotalPrice;
        this.states.existPreferenceSettings =
            selectedMultiCollectingState.existPreferenceSettings;
        this.states.usePaperBag = selectedMultiCollectingState.usePaperBag;
        this.states.showPhoneButton = selectedMultiCollectingState.showPhoneButton;
        this.states.onlineConsultation = selectedMultiCollectingState.onlineConsultation;
        this.states.noAskForReplacementItems =
            selectedMultiCollectingState.noAskForReplacementItems;
        this.states.acceptsEverything = selectedMultiCollectingState.acceptsEverything;
    }

    private setSendApprovalRequest(): boolean {
        if (this.states.collectionDate && this.states.existPreferenceSettings) {
            if (this.states.onlineConsultation) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    private sortOrderItems(orderItems: OrderItem[], isMultiCollecting = false): void {
        let itemsToPick = isMultiCollecting ? this._itemsToPick?.value ?? [] : [];
        let collectedItems = isMultiCollecting ? this._collectedItems?.value ?? [] : [];
        let replacedItems = isMultiCollecting ? this._replacedItems?.value ?? [] : [];
        let notFoundItems = isMultiCollecting ? this._notFoundItems?.value ?? [] : [];

        let notRemovedItems = orderItems.filter(
            (i) => i.removedReasonComment !== 'deleted_by_customer'
        );

        orderItems = prepareOrderItemList(notRemovedItems);

        orderItems.forEach((item) => {
            switch (item.state) {
                case null:
                    itemsToPick.push(item);
                    break;
                case OrderItemState.Collected:
                    collectedItems.push(item);
                    break;
                case OrderItemState.Replaced:
                    if (item.orderReplacementItem != null) {
                        item = this.updateReplacementItem(item);
                        collectedItems.push(item);
                    } else {
                        replacedItems.push(item);
                    }
                    break;
                case OrderItemState.NotFound:
                    notFoundItems.push(item);
            }
        });

        this._itemsToPick.next(itemsToPick);
        this._collectedItems.next(collectedItems);
        this._collectedTotalPrice.next(this.getCollectedTotalPrice());
        this._sumDeposit.next(this.getSumDeposit());
        this._replacedItems.next(replacedItems);
        this._notFoundItems.next(notFoundItems);
        this.setIsCollectionReady();
    }

    public approveItem(selectedOrderItem: OrderItem): void {
        if (this.states.isPaymentReserved || this.states.isCollectingForbidden) {
            return;
        }

        this._itemToApprove.next(selectedOrderItem);
    }

    public editReplacementItem(selectedOrderItem: OrderItem): void {
        if (this.states.isPaymentReserved || this.states.isCollectingForbidden) {
            return;
        }

        this._replacementItemToEdit.next(selectedOrderItem);
    }

    public requestApproveFromCustomer() {
        this._realtimeClient
            .invoke(
                'CheckoutMessageSendDM',
                this.collectingHub,
                this.states.orderID.toString()
            )
            .catch((err) => console.error(err));
    }

    public collectItem(selectedOrderItem: OrderItem): void {
        this.itemStateHandler(
            selectedOrderItem,
            this._itemsToPick,
            this._collectedItems,
            OrderItemState.Collected
        );

        this._realtimeClient
            .invoke(
                'collectmessagesenddm',
                this.collectingHub,
                this.states.orderID.toString(),
                selectedOrderItem.orderItemID
            )
            .catch((err) => console.error(err));
    }

    public replaceItem(replacementItem: OrderItem) {
        this.itemStateHandler(
            replacementItem,
            this._itemsToPick,
            this._replacedItems,
            OrderItemState.Replaced
        );
    }

    public collectReplacedItem(replacementItem: OrderItem) {
        this.checkAndMoveOrderItem(
            replacementItem,
            this._replacedItems,
            this._collectedItems,
            OrderItemState.Collected
        );

        this._realtimeClient
            .invoke(
                'ReplaceMessageSendDM',
                this.collectingHub,
                this.states.orderID.toString(),
                replacementItem.orderItemID
            )
            .catch((err) => console.error(err));
    }

    public itemNotFound(selectedOrderItem: OrderItem) {
        this.itemStateHandler(
            selectedOrderItem,
            this._itemsToPick,
            this._notFoundItems,
            OrderItemState.NotFound
        );

        this._realtimeClient
            .invoke(
                'missingmessagesenddm',
                this.collectingHub,
                this.states.orderID.toString(),
                selectedOrderItem.orderItemID
            )
            .catch((err) => console.error(err));
    }

    public replacementItemNotFound(selectedOrderItem: OrderItem) {
        this.itemStateHandler(
            selectedOrderItem,
            this._replacedItems,
            this._notFoundItems,
            OrderItemState.NotFound
        );

        this._realtimeClient
            .invoke(
                'missingmessagesenddm',
                this.collectingHub,
                this.states.orderID.toString(),
                selectedOrderItem.orderItemID
            )
            .catch((err) => console.error(err));
    }

    public removeItemFromCollected(selectedOrderItem: OrderItem): void {
        if (this.states.isPaymentReserved || this.states.isInspectingInProgress) {
            return;
        }

        this._ordersService.getOrderItem(selectedOrderItem.orderItemID).subscribe(
            (orderItem) => {
                orderItem.quantity = 0;

                if (
                    orderItem.orderItemDepositProductList &&
                    orderItem.orderItemDepositProductList.length > 0
                ) {
                    orderItem.orderItemDepositProductList.forEach(
                        (x) => (x.collectedQuantity = 0)
                    );
                }

                this.itemStateHandler(
                    prepareOrderItem(orderItem),
                    this._collectedItems,
                    this._itemsToPick,
                    null
                );
            },
            (error) => {
                if (error.status === 500) {
                    this._flashMessageService.showMessage(
                        this._translateService.instant('common.danger'),
                        this._translateService.instant(error.error.detail),
                        10000,
                        ColorClass.Danger
                    );
                }
            },
            () =>
                this._realtimeClient
                    .invoke(
                        'remcollectmessagesenddm',
                        this.collectingHub,
                        this.states.orderID.toString(),
                        selectedOrderItem.orderItemID
                    )
                    .catch((err) => console.error(err))
        );
    }

    public removeItemFromReplaced(replacedOrderItem: OrderItem) {
        this.itemStateHandler(
            replacedOrderItem,
            this._replacedItems,
            this._itemsToPick,
            null
        );

        this._realtimeClient
            .invoke(
                'remreplacemessagesenddm',
                this.collectingHub,
                this.states.orderID.toString(),
                replacedOrderItem.orderItemID
            )
            .catch((err) => console.error(err));
    }

    public removeItemFromBasket(notFoundItem: OrderItem) {
        if (this.states.isInspectingInProgress) {
            return;
        }

        this._ordersService.getOrderItem(notFoundItem.orderItemID).subscribe(
            (orderItem) => {
                orderItem.quantity = 0;
                this.itemStateHandler(
                    prepareOrderItem(orderItem),
                    this._collectedItems,
                    this._notFoundItems,
                    OrderItemState.NotFound
                );

                this._realtimeClient
                    .invoke(
                        'remcollectmessagesenddm',
                        this.collectingHub,
                        this.states.orderID.toString(),
                        orderItem.orderItemID
                    )
                    .catch((err) => console.error(err));
            },
            (error) => {
                if (error.status === 500) {
                    this._flashMessageService.showMessage(
                        this._translateService.instant('common.danger'),
                        this._translateService.instant(error.error.detail),
                        10000,
                        ColorClass.Danger
                    );
                }
            }
        );
    }

    public removeItemFromNotFound(notFoundItem: OrderItem) {
        if (this.states.isInspectingInProgress) {
            return;
        }
        this.itemStateHandler(notFoundItem, this._notFoundItems, this._itemsToPick, null);

        this._realtimeClient
            .invoke(
                'remmissingmessagesenddm',
                this.collectingHub,
                this.states.orderID.toString(),
                notFoundItem.orderItemID
            )
            .catch((err) => console.error(err));
    }

    public getCollectedTotalPrice(): number {
        if (this._collectedItems.getValue() == null) {
            return 0;
        }
        const getTotal = (currentTotal, item) =>
            currentTotal + priceRound(item.unitPrice * item.quantity);
        return priceRound(this._collectedItems.getValue().reduce(getTotal, 0));
    }

    public getSumDeposit(): number {
        let sumDeposit = 0;

        if (this._collectedItems.getValue() == null) {
            return 0;
        }
        this._collectedItems.subscribe((items) => {
            items.forEach((orderItem) => {
                if (
                    orderItem.orderItemDepositProductList &&
                    orderItem.orderItemDepositProductList.length > 0
                ) {
                    orderItem?.orderItemDepositProductList.forEach(
                        (orderItemDepositProduct) => {
                            sumDeposit +=
                                orderItemDepositProduct.collectedQuantity *
                                orderItemDepositProduct.price;
                        }
                    );
                }
            });

            items.forEach((orderItem) => {
                if (
                    orderItem.orderReplacementItem &&
                    orderItem.orderReplacementItem
                        .orderReplacementItemDepositProductList &&
                    orderItem.orderReplacementItem.orderReplacementItemDepositProductList
                        .length > 0
                ) {
                    orderItem?.orderReplacementItem?.orderReplacementItemDepositProductList?.forEach(
                        (orderReplacementItemDepositProduct) => {
                            sumDeposit +=
                                orderReplacementItemDepositProduct.collectedQuantity *
                                orderReplacementItemDepositProduct.price;
                        }
                    );
                }
            });
        });

        return priceRound(sumDeposit);
    }

    public createOrderItem(
        orderItemData: ICreateOrderItem,
        autoApprove: boolean = false
    ): void {
        orderItemData.orderID = this.states.orderID;
        orderItemData.providerID = this.states.providerID;
        orderItemData.isCollecting = true;
        this._ordersService.createOrderItem(orderItemData).subscribe(
            (response) => {
                if (response.status === 200) {
                    const orderItem = response.body as OrderItem;
                    orderItem.categoryID = 999999;
                    orderItem.parentCategoryID = 999999;
                    orderItem.orderItemName = orderItemData.name;
                    orderItem.isWerwProduct = orderItemData.isWerwProduct;
                    let newItemsToPick = [...this._itemsToPick.getValue(), orderItem];
                    this._itemsToPick.next(newItemsToPick);
                    if (autoApprove) {
                        this.approveItem(orderItem);
                    }
                }
            },
            (error) => {
                if (error.status == 500) {
                    this._flashMessageService.showMessage(
                        this._translateService.instant('common.danger'),
                        this._translateService.instant(error.error.detail),
                        10000,
                        ColorClass.Danger
                    );
                }
            }
        );
    }

    public createReplacementOrderItem(
        orderItem: OrderItem,
        replacementItemData: ICreateReplacementItem
    ): void {
        replacementItemData.providerID = this.states.providerID;
        this._ordersService.createReplacementItem(replacementItemData).subscribe(
            (response) => {
                if (response.status === 200) {
                    orderItem = response.body;
                    orderItem.originalItemName = orderItem.orderItemName;
                    orderItem.orderItemName = replacementItemData.replacementItemName;
                    orderItem.unitPrice = replacementItemData.replacementItemUnitPrice;
                    orderItem.quantity = this.roundQuantity(
                        replacementItemData.replacementItemQuantity
                    );
                    orderItem.price = Math.round(
                        orderItem.unitPrice * orderItem.quantity
                    );
                    orderItem.isReplace = true;
                    this.collectReplacedItem(orderItem);
                }
            },
            (error) => {
                if (error.status == 500) {
                    this._flashMessageService.showMessage(
                        this._translateService.instant('common.danger'),
                        this._translateService.instant(error?.error?.detail),
                        10000,
                        ColorClass.Danger
                    );
                }
            }
        );
    }

    public showOrderItemImage(image: OrderItemImage): void {
        this.shownOrderItemImage = image;
        this._modalService.setModalVisibility(true, 'order-item-image');
    }

    public findOrderItemByGTIN(gtin: string): OrderItem {
        let foundOrdeItem = this._itemsToPick
            .getValue()
            .find((x) => x.gtinList.includes(gtin));

        if (foundOrdeItem === undefined) {
            foundOrdeItem = this._collectedItems
                .getValue()
                .find((x) => x.gtinList.includes(gtin));
        }
        if (foundOrdeItem === undefined) {
            foundOrdeItem = this._replacedItems
                .getValue()
                .find((x) => x.gtinList.includes(gtin));
        }
        if (foundOrdeItem === undefined) {
            foundOrdeItem = this._notFoundItems
                .getValue()
                .find((x) => x.gtinList.includes(gtin));
        }
        return foundOrdeItem;
    }

    public findOrderItemsBySKU(sku: string): OrderItem[] {
        let foundOrderItems = this._itemsToPick.getValue().filter((x) => x.sku === sku);
        foundOrderItems = this._replacedItems
            .getValue()
            .filter((x) => x.sku === sku)
            .concat(foundOrderItems);

        foundOrderItems = this._notFoundItems
            .getValue()
            .filter((x) => x.sku === sku)
            .concat(foundOrderItems);

        foundOrderItems = this._collectedItems
            .getValue()
            .filter((x) => x.sku === sku)
            .concat(foundOrderItems);

        return foundOrderItems;
    }

    public checkIfOrderModificationIsBlocked(orderID: number): Observable<boolean> {
        return this._ordersService.getOrderBlockedStatus(orderID).pipe(
            map((response) => {
                if (response.ok) {
                    if (response.body == true) {
                        this._sharedService.redirectToMyTasksBecauseOrderModificationIsBlocked();
                    }
                    return response.body;
                }
            })
        );
    }

    public replaceOrderItem(orderItem: OrderItem): void {
        this.editReplacementItem(orderItem);
    }

    public removeOrderItemFromNotFound(orderItem: OrderItem): void {
        this._ordersService.deleteOrderRemovedItem(orderItem.orderItemID).subscribe(
            (resp) => {
                if (resp.status === 200) {
                    this.removeItemFromNotFound(orderItem);
                }
            },
            (error) => {
                if (error.status == 500) {
                    this._flashMessageService.showMessage(
                        this._translateService.instant('common.danger'),
                        this._translateService.instant(error.error.detail),
                        10000,
                        ColorClass.Danger
                    );
                }
            }
        );
    }

    public removeOrderItemFromReplaced(replacedOrderItem: OrderItem) {
        this._ordersService
            .deleteOrderRemovedItem(replacedOrderItem.orderItemID)
            .subscribe(
                (resp) => {
                    if (resp.status === 200) {
                        this.removeItemFromReplaced(replacedOrderItem);
                    }
                },
                (error) => {
                    if (error.status == 500) {
                        this._flashMessageService.showMessage(
                            this._translateService.instant('common.danger'),
                            this._translateService.instant(error.error.detail),
                            10000,
                            ColorClass.Danger
                        );
                    }
                }
            );
    }

    private itemStateHandler(
        orderItem: OrderItem,
        fromCollection: BehaviorSubject<OrderItem[]>,
        toCollection: BehaviorSubject<OrderItem[]>,
        newState: OrderItemState
    ) {
        if (
            !this.states.isCollectingForbidden &&
            !this.states.isPaymentReserved &&
            !toCollection.getValue().includes(orderItem)
        ) {
            this.checkIfOrderModificationIsBlocked(this.states.orderID)
                .pipe(
                    filter((isBlocked: boolean) => !isBlocked),
                    switchMap((isBlocked: boolean) => {
                        return this._ordersService.setOrderItemState(
                            orderItem.orderItemID,
                            newState
                        );
                    })
                )
                .subscribe(
                    (response) => {
                        if (response.status === 200) {
                            this.moveOrderItem(
                                orderItem,
                                fromCollection,
                                toCollection,
                                newState
                            );
                        }
                    },
                    (error) => {
                        if (error.status === 500) {
                            this._flashMessageService.showMessage(
                                this._translateService.instant('common.danger'),
                                this._translateService.instant(error.error.detail),
                                10000,
                                ColorClass.Danger
                            );
                        }
                    }
                );
        }
    }

    private checkAndMoveOrderItem(
        selectedOrderItem: OrderItem,
        fromCollection: BehaviorSubject<OrderItem[]>,
        toCollection: BehaviorSubject<OrderItem[]>,
        newState: OrderItemState
    ) {
        this.checkIfOrderModificationIsBlocked(this.states.orderID)
            .pipe(filter((isBlocked: boolean) => !isBlocked))
            .subscribe((response) =>
                this.moveOrderItem(
                    selectedOrderItem,
                    fromCollection,
                    toCollection,
                    newState
                )
            );
    }

    private moveOrderItem(
        selectedOrderItem: OrderItem,
        fromCollection: BehaviorSubject<OrderItem[]>,
        toCollection: BehaviorSubject<OrderItem[]>,
        newState: OrderItemState
    ) {
        let newFromCollection = fromCollection.getValue();

        newFromCollection.forEach((item, index) => {
            if (item.orderItemID === selectedOrderItem.orderItemID) {
                newFromCollection.splice(index, 1);
            }
        });

        fromCollection.next(newFromCollection);

        let newToCollection = toCollection.getValue();
        selectedOrderItem.state = newState;

        newToCollection.push(selectedOrderItem);

        toCollection.next(newToCollection);

        this._collectedTotalPrice.next(this.getCollectedTotalPrice());
        this._sumDeposit.next(this.getSumDeposit());

        this.setIsCollectionReady();

        if (!this.states.isCollectionReady) {
            this.states.resetCheckoutButtonStates();
        }
    }

    private updateReplacementItem(item: OrderItem): OrderItem {
        item.originalItemName = item.orderItemName;
        item.orderItemName = item.orderReplacementItem.itemName;
        item.quantity = item.orderReplacementItem.quantity;
        item.unitPrice = item.orderReplacementItem.unitPrice;
        item.productPhotoID = item.orderReplacementItem.productPhotoID;
        item.isReplace = item.isReplace;
        item.providerProductImageURL = item.orderReplacementItem.providerProductImageURL;
        item.price = Math.round(item.unitPrice * item.quantity);
        item.state = OrderItemState.Collected;
        return prepareOrderItem(item);
    }

    private setIsCollectionReady(): void {
        this.states.isCollectionReady =
            this._itemsToPick.getValue().length < 1 &&
            this._replacedItems.getValue().length < 1;
    }

    public existItemRejectedByUser(): boolean {
        return (
            this._collectedItems
                .getValue()
                .filter((x) => x.orderReplacementItem?.quantityRequestedByUser === 0)
                .length > 0
        );
    }

    private reorderItems(items: OrderItem[]): OrderItem[] {
        return items.sort((currentItem, nextItem) => {
            return (
                currentItem.parentCategoryID - nextItem.parentCategoryID ||
                currentItem.categoryID - nextItem.categoryID
            );
        });
    }

    private roundQuantity(num: number): number {
        return Math.round((num + Number.EPSILON) * 1000) / 1000;
    }
}
