import { Component, Input } from '@angular/core';
import { OrderItem, ModalService, ColorClass } from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { OrderItemState, priceRound } from 'shared';
import { OrderItemImage } from 'dm-src/types/order-item-image';
import { CollectingStatesService } from '../../collecting-states.service';
import { OrderRemovedItemReasons } from 'projects/shared/src/lib/types/order-removed-item-reasons';

@Component({
    selector: '[app-order-item]',
    templateUrl: './order-item.component.html',
    styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent {
    @Input() orderItem: OrderItem;
    @Input() orderRemovedItemReasons: OrderRemovedItemReasons[];
    public orderItemState = OrderItemState;
    public ColorClass = ColorClass;
    public fullSizeMap: boolean = false;

    constructor(
        public states: CollectingStatesService,
        public modalService: ModalService,
        private _orderItemsService: CollectingItemsService
    ) {}

    public onImageClick(): void {
        const image = new OrderItemImage();
        image.url = this.orderItem.itemImageSrc;
        image.orderItemName = this.orderItem.orderItemName;
        this._orderItemsService.showOrderItemImage(image);
    }

    public openProductLocationMap(): void {
        this.modalService.setModalVisibility(
            true,
            'product-location-map' + this.orderItem.orderItemID
        );
    }

    public mapSizeChange(): void {
        this.fullSizeMap = !this.fullSizeMap;
    }
}
