<lib-modal
    [title]="title"
    [bgColorClass]="ColorClass.Success"
    [componentID]="'package-verify-modal'"
>
    <div *ngIf="task" class="text-center">
        <p *ngIf="isDeliveringTask" class="h3">
            <strong>{{ 'my-tasks.order-code' | translate }}:</strong>
            {{ task.orderCode }}
        </p>
        <p *ngIf="isDeliveringTask" class="h4">
            <strong>{{ 'my-tasks.elapsed-time' | translate }}:</strong>
            {{ elapsedTime }}
        </p>
        <div *ngIf="isDeliveringTask">
            <img [src]="qrImage" *ngIf="!isInProgress; else inProgress" />
            <ng-template #inProgress>
                <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                ></span>
                <span>{{ 'common.working' | translate }}...</span>
            </ng-template>
        </div>
        <app-order-items-view-modal
            [isCollectingTask]="isCollectingTask"
            [orderItems]="orderItems"
        ></app-order-items-view-modal>
    </div>
</lib-modal>
