import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AssignTaskToUser } from '../../dtos/assign-task-to-user';
import { TaskList } from '../../models/task-list';
import { CollectingDoneResponse } from '../../dtos/collecting-done-response';
import { TaskPageQuery } from '../../dtos/task-page-query';
import { TaskListPage } from '../../models/task-list-page';
import { TaskListFilterOptions } from '../../types/task-list-filter-options';
import { AssignDeliveringTask } from '../../dtos/assign-delivering-task';
import { SetDeliveryTaskFailed } from '../../dtos/set-delivery-task-failed';
import { DeliveryTaskState } from '../../types/delivery-task-state';
import { LatLng } from '../../types/latlng';
import { SumUpFinalize } from '../../dtos/sum-up-finalize';
import { OrderPaymentHistory } from '../../models/order-payment-history';
import { CashOnDeliveryFinalize } from '../../dtos/cash-on-delivery-finalize';

export const TASKS_SERVICE_IMPL = new InjectionToken<ITasksService>('tasksServiceImpl');

export interface ITasksService {
    getTasks(requestParams: TaskPageQuery): Observable<TaskListPage>;
    getReturnTasks(userID: string): Observable<TaskList>;
    getMyTasks(userId?: string): Observable<TaskList>;
    assignToUser(requestBody: AssignTaskToUser): Observable<HttpResponse<void>>;
    assignDeliveringTask(
        requestBody: AssignDeliveringTask
    ): Observable<HttpResponse<void>>;
    unassignTasks(taskIDs: string[]): Observable<HttpResponse<void>>;
    acceptCollectingTask(taskID: string): Observable<HttpResponse<void>>;
    checkIfTheUserIsTheSame(taskID: string): Observable<boolean>;
    rejectCollectingTask(taskID: string): Observable<HttpResponse<void>>;
    startCollectingTask(
        taskID: string,
        providerShopID: number | null
    ): Observable<HttpResponse<void>>;
    retryCollectingTask(taskID: string): Observable<HttpResponse<void>>;
    failDeliveryTask(requestBody: SetDeliveryTaskFailed): Observable<HttpResponse<void>>;
    acceptDeliveringTask(taskID: string): Observable<HttpResponse<void>>;
    startDeliveringTask(taskID: string): Observable<HttpResponse<void>>;
    setDeliveringTaskDone(
        taskID: string,
        orderID: number,
        lat: number,
        long: number
    ): Observable<HttpResponse<CollectingDoneResponse>>;
    setDeliveringTaskFailed(taskID: string): Observable<HttpResponse<void>>;
    rejectDeliveringTask(taskID: string): Observable<HttpResponse<void>>;
    setTaskState(
        taskID: string,
        taskState: DeliveryTaskState,
        notes?: string
    ): Observable<HttpResponse<void>>;
    createCollectingTask(orderID: number): Observable<HttpResponse<void>>;
    createContainerPlacingTask(orderID: number): Observable<HttpResponse<void>>;
    createDeliveringTask(orderID: number): Observable<HttpResponse<void>>;
    getFilterOptions(): Observable<TaskListFilterOptions>;
    getNextTasksAuto(userId: string, supplierPosition: LatLng): Observable<string[]>;
    isPaidOnDelivery(deliveryTaskID: string): Observable<boolean>;
    isSuccessfulPayment(deliveryTaskID: string): Observable<boolean>;
    finalize(finalizeInfo: SumUpFinalize): Observable<HttpResponse<void>>;
    cashOnDeliveryFinalize(
        finalizeInfo: CashOnDeliveryFinalize
    ): Observable<HttpResponse<void>>;
    createOrderPaymentHistory(
        orderPaymentHistory: OrderPaymentHistory
    ): Observable<HttpResponse<OrderPaymentHistory>>;
    getOrderPaymentHistory(taskID: string): Observable<OrderPaymentHistory>;
    startMultiPicking(orderIDList: number[]): Observable<HttpResponse<void>>;
}
