<li class="list-group-item">
    <table>
        <caption *ngIf="label !== undefined">
            <span class="badge badge-primary" *ngIf="label !== undefined">
                {{ label }}
            </span>
        </caption>
        <tbody>
            <tr>
                <td class="center" rowspan="4" class="w-50">
                    <app-order-item-state-view
                        *ngIf="
                            !isCollectingTask && orderItem.orderReplacementItem === null
                        "
                        [orderItemState]="orderItem.state"
                    ></app-order-item-state-view>
                    <img style="width: 100px" [src]="orderItem.itemThumbnailSrc" />
                </td>
                <td class="text-left pl-1">
                    <strong>{{ 'common.sku' | translate }}:</strong>
                </td>
                <td class="text-left">{{ orderItem.sku || '-' }}</td>
            </tr>
            <tr>
                <td class="text-left pl-1">
                    <strong>{{ 'common.price' | translate }}:</strong>
                </td>
                <td class="text-left">
                    {{ orderItem.price | priceround }} {{ 'common.currency' | translate }}
                </td>
            </tr>
            <tr>
                <td class="text-left pl-1">
                    <strong>{{ 'collecting.original' | translate }}:</strong>
                </td>
                <td class="text-left">{{ orderItem.orderedQuantity | decimal }}</td>
            </tr>
            <tr>
                <td class="text-left pl-1">
                    <strong>{{ 'collecting.collected' | translate }}:</strong>
                </td>
                <td class="text-left">{{ orderItem.collectedQuantity | decimal }}</td>
            </tr>
            <tr>
                <td class="center">
                    <span class="text-small">
                        {{ orderItem.orderItemName }}
                    </span>
                </td>
                <td class="text-left pl-1">
                    <strong>{{ 'collecting.approved' | translate }}: </strong>
                </td>
                <td class="text-left">{{ quantity | decimal }}</td>
            </tr>
        </tbody>
    </table>
</li>
