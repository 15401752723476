<div id="order-items-container">
    <div
        class="row mb-3 infos-container"
        [ngClass]="{
            'infos-container mb-4': isMultiCollecting,
            'row mb-3': !isMultiCollecting
        }"
    >
        <div
            *ngIf="isMultiCollecting"
            class="btn btn-dark multi-picking-box all-order"
            (click)="states.setFlteredMultiCollectingByColor('')"
        >
            {{ 'collecting.all-order' | translate }}
        </div>
        <div
            *ngFor="
                let multiCollectingState of collectingItemsService.states
                    .multiCollectingStates
            "
            [ngClass]="{
                'multi-picking-box pl-3': isMultiCollecting,
                'col-12': !isMultiCollecting
            }"
        >
            <ng-container
                *ngIf="
                    userSelectedMultipickingColor == '' ||
                    multiCollectingState.multipickingTaskIdentifierColor ==
                        userSelectedMultipickingColor
                "
            >
                <div
                    class="multi-picking-task-identifier"
                    *ngIf="
                        multiCollectingState.multipickingTaskIdentifierChar &&
                        isMultiCollecting
                    "
                    [ngStyle]="{
                        'background-color':
                            multiCollectingState.multipickingTaskIdentifierColor
                    }"
                    (click)="
                        states.setFlteredMultiCollectingByColor(
                            multiCollectingState.multipickingTaskIdentifierColor
                        );
                        collectingItemsService.setDefaultStatedToSelectedMultiCollectingState(
                            multiCollectingState.orderID
                        )
                    "
                >
                    {{ multiCollectingState.multipickingTaskIdentifierChar }}
                </div>

                <div
                    style="margin: 2px; border-radius: 15px"
                    class="p-1 pl-3 text-center h-100 border"
                    [ngClass]="
                        multiCollectingState.orderID ===
                            collectingItemsService.states.orderID && isMultiCollecting
                            ? 'border-success'
                            : 'border-dark'
                    "
                >
                    <button
                        class="btn btn-primary chat-button"
                        *ngIf="multiCollectingState.customerChat"
                        (click)="openChat(multiCollectingState.customerChatInfo)"
                    >
                        {{ multiCollectingState.customerChatInfo.channelUrl }}
                        {{ 'common.chat' | translate }}
                    </button>
                    <div>
                        <b>{{ multiCollectingState.customerName }}</b>
                    </div>
                    <div *ngIf="multiCollectingState.usePaperBag; else multiUseBaggy">
                        {{ 'collecting.ask-for-paper-bag' | translate }} <br />
                    </div>
                    <ng-template #multiUseBaggy>
                        <div *ngIf="multiCollectingState.usePaperBag === false">
                            {{ 'collecting.no-ask-for-paper-bag' | translate }} <br />
                        </div>
                    </ng-template>

                    <div *ngIf="multiCollectingState.onlineConsultation">
                        {{ 'collecting.online-consultation' | translate }} <br />
                    </div>
                    <div *ngIf="multiCollectingState.noAskForReplacementItems">
                        {{ 'collecting.no-replacement-item' | translate }} <br />
                    </div>
                    <div *ngIf="multiCollectingState.acceptsEverything">
                        {{ 'collecting.accepts-all-repl-items' | translate }}<br />
                    </div>
                    <div *ngIf="multiCollectingState.showPhoneButton">
                        <div>
                            {{ 'collecting.phone-consultation' | translate }}<br />
                            <a
                                href="tel:{{ multiCollectingState.customerPhone }}"
                                class="button"
                            >
                                <u>
                                    {{ multiCollectingState.customerPhone }}
                                </u>
                            </a>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <app-order-items-container
        [classes]="'text-white bg-info'"
        [name]="'items-to-pick'"
        [icon]="'fa-search'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.itemsToPick"
        [title]="'collecting.items-to-pick' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <app-order-items-container
        [classes]="'text-white bg-success'"
        [name]="'collected-items'"
        [icon]="'fa-cart-arrow-down'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.collectedItems"
        [currentTotal]="collectingItemsService.collectedTotalPrice"
        [sumDeposit]="collectingItemsService.sumDeposit"
        [title]="'collecting.collected-items' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <app-order-items-container
        [classes]="'text-white bg-warning'"
        [name]="'replaced-items'"
        [icon]="'fa-refresh'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.replacedItems"
        [title]="'collecting.replaced-items' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <app-order-items-container
        [classes]="'text-white bg-danger'"
        [name]="'missing-items'"
        [icon]="'fa-exclamation-triangle'"
        [parent]="'order-items-container'"
        [orderItems]="collectingItemsService.notFoundItems"
        [title]="'collecting.missing-items' | translate"
        [orderRemovedItemReasons]="collectingItemsService.orderRemovedItemReasons"
    >
    </app-order-items-container>
    <button
        class="btn btn-outline-primary w-100"
        *ngIf="customerChat && !isMultiCollecting"
        (click)="openChat()"
    >
        {{ 'common.customer-chat' | translate }}
    </button>
    <app-customer-card-container></app-customer-card-container>
    <ng-container *ngIf="!isMultiCollecting; else backToTasksButton">
        <app-markup-collecting-buttons
            *ngIf="states.hasMarkupPayment; else collectingButtons"
            [timeoutFinishedEvents]="collectingItemsService.timeoutFinishedSubject"
        >
        </app-markup-collecting-buttons>
        <ng-template #collectingButtons>
            <app-collecting-buttons></app-collecting-buttons>
        </ng-template>
        <button
            (click)="taskFailedModalService.setFailModalVisible(true)"
            [disabled]="states.isCollectingForbidden"
            class="btn btn-block btn-outline-danger task-action-btn w-75 mx-auto my-3"
        >
            <i class="fa fa-ban"></i>{{ 'collecting.task-failed' | translate }}
        </button>
    </ng-container>
    <ng-template #backToTasksButton>
        <a
            [routerLink]="['/my-tasks']"
            class="btn btn-block btn-outline-info task-action-btn w-75 mx-auto my-3"
        >
            <i class="fa fa-arrow-left"></i>{{ 'navigation.my-tasks' | translate }}
        </a>
    </ng-template>
</div>
<app-actions-toolbar></app-actions-toolbar>
<app-collecting-modals></app-collecting-modals>
