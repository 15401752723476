<div>
    <div class="font-weight-bold pb-1">
        {{ 'common.deliverer-notes' | translate }}
    </div>

    <div>
        <form [formGroup]="delivererNotesForm" *ngIf="isEditMode; else notesTemplate">
            <div class="form-group row">
                <div class="col-12">
                    <div class="input-group is-invalid">
                        <textarea
                            class="form-control"
                            rows="3"
                            formControlName="delivererNotes"
                        >
                        </textarea>
                    </div>
                </div>
            </div>
        </form>
        <ng-template #notesTemplate>
            <div class="pb-2">
                {{ delivererNotes }}
            </div>
        </ng-template>
    </div>

    <div *ngIf="isVisible" class="btn-group" role="group">
        <button
            *ngIf="!isEditMode"
            type="button"
            class="btn btn-warning"
            (click)="isEditMode = !isEditMode"
        >
            {{ 'common.edit' | translate }}
        </button>
        <button
            *ngIf="isEditMode"
            type="button"
            class="btn btn-primary"
            (click)="updateDelivererNotes()"
        >
            {{ 'common.save' | translate }}
        </button>
        <button
            *ngIf="isEditMode"
            type="button"
            class="btn btn-danger"
            (click)="isEditMode = !isEditMode"
        >
            {{ 'common.cancel' | translate }}
        </button>
    </div>
</div>
