import { Inject, Injectable } from '@angular/core';
import { CollectingStatesService } from './collecting-states.service';
import { CollectingPayment } from '../../../dtos/collecting-payment';
import {
    AUTH_SERVICE_IMPL,
    IAuthenticationService,
    GEOLOCATION_SERVICE_IMPL,
    IGeolocationService,
    LatLng,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    OrderStateEventLog,
    EventType,
    ActionType,
} from 'shared';
import {
    COLLECTING_SERVICE_IMPL,
    ICollectingService,
} from '../../../services/collecting/icollecting.service';
import { FlashMessageService } from 'shared';
import { ModalService } from 'shared';
import { ProgressLineService } from 'shared';
import { CloseCollectingTask } from '../../../dtos/close-collecting-task';
import { PaymentType } from 'shared';
import { CollectingErrorsService } from './collecting-errors.service';
import { CollectingSharedService } from './collecting-shared.service';
import { IosAndAndroidNatvieFunctionsService } from 'dm-src/services/ios-and-android-natvie-functions/ios-and-android-natvie-functions.service';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';
import { filter, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CollectingActionsService {
    private _billPhotoImageContent: string;
    private _billPhotoID: string;
    private _billPhotoImageExtension: string;
    private totalPrice: number;
    private postMessageFlag: 'Closed' | 'Finished';
    constructor(
        private _states: CollectingStatesService,
        private _errorsService: CollectingErrorsService,
        @Inject(AUTH_SERVICE_IMPL) private _authServices: IAuthenticationService,
        @Inject(COLLECTING_SERVICE_IMPL)
        private _collectingService: ICollectingService,
        private _modalService: ModalService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        private _flashMessageService: FlashMessageService,
        private _progressLineService: ProgressLineService,
        private _sharedService: CollectingSharedService,
        private _iosAndAndroidNatvieFunctionsService: IosAndAndroidNatvieFunctionsService,
        private isWrapping: IsWrappingService,
        @Inject(GEOLOCATION_SERVICE_IMPL)
        private _geolocationService: IGeolocationService
    ) {
        _iosAndAndroidNatvieFunctionsService
            .getCurrentPostMessage()
            .pipe(filter((response) => response.functionName === 'GET_LAT_LON'))
            .subscribe((response) => {
                if (response.result.taskID === '-1000') {
                    const latLonData = response.result.message;
                    const data = new CloseCollectingTask();
                    data.billPhotoImageContent = this._billPhotoImageContent;
                    data.billPhotoID = this._billPhotoID;
                    data.orderID = this._states.orderID;
                    data.billPhotoImageExtension = this._billPhotoImageExtension;
                    data.userID = this._authServices.currentUser.userID;
                    data.lat = latLonData?.coords?.latitude;
                    data.long = latLonData?.coords?.longitude;
                    data.taskId = this._states.taskID;
                    data.totalPrice = this.totalPrice;

                    if (this.postMessageFlag === 'Closed') {
                        this.collectingClose(data);
                    } else if (this.postMessageFlag === 'Finished') {
                        this.finishCollectingPaymentFunction(data);
                    }
                }
            });
    }

    public setBillPhotoImageContent(imageContent: string, imageExtension: string): void {
        this._billPhotoImageContent = imageContent;
        this._billPhotoImageExtension = imageExtension;
        this._states.isPhotoReady = true;
    }

    public unsetBillPhotoImageContent(): void {
        this._billPhotoImageContent = '';
        this._billPhotoImageExtension = '';
        this._states.isPhotoReady = false;
    }

    public setBillPhotoID(billPhotoID: string): void {
        this._billPhotoID = billPhotoID;
    }

    public unsetBillPhotoID(): void {
        this._billPhotoID = '';
    }

    public startCollectingPayment(): void {
        const requestBody = new CollectingPayment();
        requestBody.orderID = this._states.orderID;
        requestBody.supplierID = this._authServices.currentUser.userID;
        this._states.isStartPaymentInProgress = true;
        this._collectingService.startPayment(requestBody).subscribe(
            (response) => {
                let isBillValidationNeeded = false;
                this._states.isStartPaymentInProgress = false;
                if (response.status !== 200) {
                    this._errorsService.showErrorMessage();
                } else if (response.body.isSuccessful) {
                    this._states.isPaymentReserved = true;
                } else if (response.body.isVirtualAccountSuspended) {
                    this._modalService.setModalVisibility(
                        true,
                        'virtual-account-suspended'
                    );
                    isBillValidationNeeded = true;
                } else if (response.body.isVirtualAccountBalanceUnavailable) {
                    this._modalService.setModalVisibility(
                        true,
                        'virtual-payment-reservation-failed'
                    );
                    isBillValidationNeeded = true;
                } else if (!response.body.isTotalPriceValid) {
                    this._modalService.setModalVisibility(true, 'invalid-total-price');
                } else if (!response.body.isSuccessful) {
                    this._modalService.setModalVisibility(
                        true,
                        'virtual-payment-reservation-failed'
                    );
                }

                if (isBillValidationNeeded) {
                    this.switchToBillValidation();
                }
            },
            (error) => {
                this._states.isStartPaymentInProgress = false;
                this._errorsService.handlePaymentErrorResponse(
                    error.status,
                    this.startCollectingPayment.bind(this)
                );
            }
        );
    }

    public releaseReservedPayment(): void {
        const requestBody = new CollectingPayment();
        requestBody.orderID = this._states.orderID;
        requestBody.supplierID = this._authServices.currentUser.userID;
        this._states.isReleasePaymentInProgress = true;

        this._collectingService.releaseReservedPayment(requestBody).subscribe(
            (response) => {
                this._states.isReleasePaymentInProgress = false;
                if (response.status === 200) {
                    if (response.body.isPaymentSucceeded) {
                        this.showModal('payment-is-succeeded-no-go-back');
                    } else if (response.body.hasOtherTaskPaymentInProgress) {
                        this.showModal('payment-in-progress');
                    } else {
                        this._states.resetCheckoutButtonStates();
                    }
                } else {
                    this._flashMessageService.showStoredMessage();
                }
            },
            (error) => {
                this._states.isReleasePaymentInProgress = false;
                this._errorsService.handlePaymentErrorResponse(
                    error.status,
                    this.releaseReservedPayment.bind(this)
                );
            }
        );
    }

    public async finishCollectingPayment(): Promise<void> {
        if (window.ReactNativeWebView) {
            this.postMessageFlag = 'Finished';
            await this.getCloseCollectingTaskData();
        } else {
            this.finishCollectingPaymentFunction(await this.getCloseCollectingTaskData());
        }
    }

    public finishCollectingPaymentFunction(
        closeCollectingTask: CloseCollectingTask
    ): void {
        this._states.isFinishPaymentInProgress = true;
        this._collectingService.finishPayment(closeCollectingTask).subscribe(
            (response) => {
                this._states.isFinishPaymentInProgress = false;
                if (response.status !== 200) {
                    this._modalService.setModalMessage(response.statusText);
                    this._modalService.setModalVisibility(
                        true,
                        'payment-finalization-failed'
                    );
                    this.switchToBillValidation();
                } else if (response.body.isPaymentSucceeded) {
                    this._sharedService.redirectToMyTasks();
                } else {
                    if (!response.body.isVirtualPaymentAvailable) {
                        this._modalService.setModalVisibility(
                            true,
                            'virtual-checkout-payment-is-unavailable'
                        );
                        this.switchToBillValidation();
                    } else if (!response.body.isVirtualPaymentSucceeded) {
                        this._modalService.setModalVisibility(
                            true,
                            'virtual-checkout-payment-failed'
                        );
                        this.switchToBillValidation();
                    } else if (response.body.isPaidBefore) {
                        this._modalService.setModalVisibility(
                            true,
                            'payment-is-already-finished'
                        );
                    } else {
                        this._modalService.setModalVisibility(
                            true,
                            'payment-finalization-failed'
                        );
                        this._states.isPaymentFinalizationDisabled = true;
                    }
                }
            },
            (error) => {
                this._states.isFinishPaymentInProgress = false;
                this._errorsService.handlePaymentErrorResponse(
                    error.status,
                    this.finishCollectingPayment.bind(this)
                );
            }
        );
    }

    public closeCollectingTask(totalPrice: number) {
        let supplierPosition = new LatLng(0, 0);
        if (window.ReactNativeWebView) {
            this.postMessageFlag = 'Closed';
            this.totalPrice = totalPrice;
            this.getCurrentLatLonPosition();
        } else if (
            (this._iosAndAndroidNatvieFunctionsService.osType.toLowerCase() ===
                'android' ||
                this._iosAndAndroidNatvieFunctionsService.osType.toLowerCase() ===
                    'ios') &&
            this.isWrapping.isWrapping()
        ) {
            this.closeCollectingTaskWithLatLong(
                this.getCurrentLatLonPosition(),
                totalPrice
            );
        } else {
            this._geolocationService
                .getCurrentPosition()
                .subscribe((positionResponse) => {
                    if (positionResponse.coords !== undefined) {
                        supplierPosition = new LatLng(
                            positionResponse.coords.latitude,
                            positionResponse.coords.longitude
                        );
                        this.closeCollectingTaskWithLatLong(
                            supplierPosition,
                            totalPrice
                        ).then();
                    }
                });
        }
    }

    private async closeCollectingTaskWithLatLong(latlng: LatLng, totalPrice: number) {
        const requestBody = await this.getCloseCollectingTaskData();

        requestBody.totalPrice = totalPrice;
        requestBody.lat = latlng.lat;
        requestBody.long = latlng.lng;

        this.collectingClose(requestBody);
    }

    private async getCloseCollectingTaskData(): Promise<CloseCollectingTask | null> {
        let supplierPosition = new LatLng(0, 0);
        let data = new CloseCollectingTask();

        if (window.ReactNativeWebView) {
            this.getCurrentLatLonPosition();
            return null;
        } else {
            if (
                this._iosAndAndroidNatvieFunctionsService.osType === 'Android' &&
                this.isWrapping.isWrapping()
            ) {
                supplierPosition = this.getCurrentLatLonPosition();
                data = this.getCloseCollectingTask(supplierPosition);
                return data;
            } else {
                return new Promise((resolve, reject) => {
                    this._geolocationService.getCurrentPosition().subscribe(
                        (positionResponse) => {
                            if (positionResponse.coords !== undefined) {
                                supplierPosition = new LatLng(
                                    positionResponse.coords.latitude,
                                    positionResponse.coords.longitude
                                );
                                data = this.getCloseCollectingTask(supplierPosition);
                                resolve(data);
                            }
                        },
                        (err) => {
                            reject(err);
                        }
                    );
                });
            }
        }
    }

    private getCloseCollectingTask(supplierPosition: LatLng): CloseCollectingTask {
        const closeCollectingTask = new CloseCollectingTask();
        closeCollectingTask.billPhotoImageContent = this._billPhotoImageContent;
        closeCollectingTask.billPhotoID = this._billPhotoID;
        closeCollectingTask.orderID = this._states.orderID;
        closeCollectingTask.billPhotoImageExtension = this._billPhotoImageContent
            .split(';')[0]
            .split('/')[1];
        closeCollectingTask.userID = this._authServices.currentUser.userID;
        closeCollectingTask.lat = supplierPosition.lat;
        closeCollectingTask.long = supplierPosition.lng;
        closeCollectingTask.taskId = this._states.taskID;
        return closeCollectingTask;
    }

    private switchToBillValidation(): void {
        this._states.isBillApprovalNeeded = true;
        this._states.isPhotoReady = false;
    }

    private showModal(modalID: string) {
        this._modalService.setModalVisibility(true, modalID);
    }

    private getCurrentLatLonPosition(): LatLng {
        if (window.ReactNativeWebView) {
            this._iosAndAndroidNatvieFunctionsService.getCurrentLocation(
                '-1000',
                'CollectingActionsService'
            );
        } else {
            const latLon = JSON.parse(
                this._iosAndAndroidNatvieFunctionsService.getCurrentLocation()
            );

            return new LatLng(latLon?.lat, latLon?.lon);
        }
    }

    private collectingClose(closeCollectingTask: CloseCollectingTask): void {
        this._collectingService.close(closeCollectingTask).subscribe(
            (response) => {
                if (response.status === 200) {
                    this._states.isPhotoReady = false;
                    this._states.isCollectionReady = false;
                    this._states.isPaymentReserved = response.body.isPaymentSucceeded;
                    this._states.isInvoicingSucceeded =
                        response.body.isInvoicingSucceeded;

                    this._states.setTotalPriceApprovalModalVisible(false);

                    if (!response.body.isTotalPriceValid) {
                        this.showModal('invalid-total-price');
                    } else if (
                        (response.body.paymentType === PaymentType.BarionCard ||
                            response.body.paymentType === PaymentType.RevolutCard ||
                            response.body.paymentType === PaymentType.ApplePay ||
                            response.body.paymentType === PaymentType.GooglePay) &&
                        !response.body.isPaymentSucceeded
                    ) {
                        this.showModal('payment-finalization-failed');
                    } else {
                        this._sharedService.redirectToMyTasks();
                    }
                }
            },
            () => {
                this._states.setTotalPriceApprovalModalVisible(false);
                this._errorsService.showErrorMessage();
            }
        );
    }
}
