import { Inject, Injectable, Injector } from '@angular/core';
import { IOrdersService, ORDERS_SERVICE_IMPL } from 'shared';
import { Order } from 'dm-src/models/order';
import { BehaviorSubject, Observable } from 'rxjs';
import { ListService } from 'shared';
import { OrderListFilterOptions } from 'shared';
import { CustomerContactData } from 'shared';
import { HttpResponse } from '@angular/common/http';
import { OrderDetails } from 'shared';
import { UpdateCustomerNotes } from 'shared';
import { UpdateDelivererNotes } from 'shared';
import { share } from 'rxjs/operators';
import { TASKS_SERVICE_IMPL } from 'shared';
import { FlashMessageService } from 'shared';
import { MarkupCorrectionDto } from 'projects/shared/src/lib/dtos/markup-correction';
import { OrderHistory } from 'shared';
import { SupportCommentDto } from 'projects/shared/src/lib/dtos/support-comment.dto';

@Injectable({
    providedIn: 'root',
})
export class OrdersService {
    private _selectedOrder: BehaviorSubject<Order>;
    private _customerData: BehaviorSubject<CustomerContactData>;
    private _orderDetails: BehaviorSubject<OrderDetails>;
    private _orders: BehaviorSubject<Order[]>;
    private _filterOptions: BehaviorSubject<OrderListFilterOptions>;
    private _isOrderDetailsModalShown: BehaviorSubject<boolean>;
    private _selectedOrderID = null;

    public get isOrderDetailsModalShown() {
        return this._isOrderDetailsModalShown.asObservable();
    }

    public get filterOptions(): Observable<OrderListFilterOptions> {
        return this._filterOptions.asObservable();
    }

    public get orders(): Observable<Order[]> {
        return this._orders.asObservable();
    }

    public get selectedOrder(): Observable<Order> {
        return this._selectedOrder.asObservable();
    }

    public get customerData(): Observable<CustomerContactData> {
        return this._customerData.asObservable();
    }

    public get orderDetails(): Observable<OrderDetails> {
        return this._orderDetails.asObservable();
    }

    public setCustomerData(customerContactData: CustomerContactData): void {
        this._customerData.next(customerContactData);
    }

    public setSelectedOrder(order: Order): void {
        this._selectedOrder.next(order);
        this._selectedOrderID = order.orderID;
    }

    public setOrderDetails(orderDetails: OrderDetails): void {
        this._orderDetails.next(orderDetails);
    }

    constructor(
        @Inject(ORDERS_SERVICE_IMPL) private _ordersService: IOrdersService,
        private _serviceProvider: Injector,
        private _listService: ListService
    ) {
        this._isOrderDetailsModalShown = new BehaviorSubject<boolean>(false);
        this._filterOptions = new BehaviorSubject<OrderListFilterOptions>(null);
        this._orders = new BehaviorSubject<Order[]>([]);
        this._selectedOrder = new BehaviorSubject<Order>(null);
        this._customerData = new BehaviorSubject<CustomerContactData>(null);
        this._orderDetails = new BehaviorSubject<OrderDetails>(null);
    }

    public initOrdersList() {
        if (this._filterOptions.getValue() == null) {
            this._ordersService.getFilterOptions().subscribe((filterOptions) => {
                this._filterOptions.next(filterOptions);
            });
        }

        this._listService.onQueryResult = (result) => {
            if (result === null) {
                result = [];
            }
            this._orders.next(result);
        };
        this._listService.listApiEndpoint = 'orders';
        this._listService.resetList();
        this._listService.getResults();
    }

    public setOrderItemsModalVisibility(isVisible: boolean) {
        this._isOrderDetailsModalShown.next(isVisible);
    }

    public selectOrder(selectedOrderID: number, forceUpdate = false): void {
        if (this._selectedOrderID !== selectedOrderID || forceUpdate) {
            this._ordersService.getOrder(selectedOrderID).subscribe((response) => {
                if (response.status === 200) {
                    this._selectedOrder.next(response.body);
                    this._ordersService
                        .getCustomerContactData(selectedOrderID)
                        .subscribe((customerData) =>
                            this._customerData.next(customerData)
                        );
                    this.updateOrderDetails(selectedOrderID);
                }
            });
        }

        this._selectedOrderID = selectedOrderID;
    }

    public resetSelectedOrder(): void {
        this._selectedOrderID = null;
    }

    public refundPayment() {
        return this._ordersService.refundOrderPayment(this._selectedOrderID);
    }

    public getAvailableTimeSlots() {
        return this._ordersService.getAvailableTimeSlots(this._selectedOrderID);
    }

    public changeEstimatedDeliveryEnd(estimatedDeliveryEnd: Date) {
        return this._ordersService.changeEstimatedDeliveryEnd(
            this._selectedOrderID,
            estimatedDeliveryEnd
        );
    }

    public changeTimeSlotForDelivery(timeSlot: Date) {
        return this._ordersService.changeTimeSlotForDelivery(
            this._selectedOrderID,
            timeSlot
        );
    }

    public updateCustomerContactData(
        contactData: CustomerContactData
    ): Observable<HttpResponse<void>> {
        contactData.orderID = this._selectedOrderID;
        const request = this._ordersService
            .updateCustomerContactData(contactData)
            .pipe(share());

        request.subscribe((response) => {
            if (response.status === 200) {
                this._customerData.next(contactData);
            }
        });
        return request;
    }

    public updateCustomerNotes(notes: string): Observable<HttpResponse<void>> {
        const requestBody = new UpdateCustomerNotes();
        requestBody.notes = notes;
        requestBody.orderID = this._selectedOrderID;
        return this._ordersService.updateCustomerNotes(requestBody);
    }

    public updateDelivererNotes(delivererNotes: string, orderID: number): Observable<HttpResponse<void>> {
        const requestBody = new UpdateDelivererNotes();
        requestBody.delivererNotes = delivererNotes;
        requestBody.orderID = orderID;
        return this._ordersService.updateDelivererNotes(requestBody);
    }

    public updateSupportComment(comment: string): Observable<HttpResponse<void>> {
        const requestBody = new SupportCommentDto();
        requestBody.comment = comment;
        requestBody.orderID = this._selectedOrderID;
        return this._ordersService.updateSupportComment(requestBody);
    }

    public createCollectingTask(): Observable<HttpResponse<void>> {
        const tasksService = this._serviceProvider.get(TASKS_SERVICE_IMPL);
        const apiCall = tasksService
            .createCollectingTask(this._selectedOrderID)
            .pipe(share());

        apiCall.subscribe(
            (response) => {
                this.updateOrderDetails(this._selectedOrderID);
            },
            () => {
                const flashMessageService =
                    this._serviceProvider.get(FlashMessageService);
                flashMessageService.showStoredMessage('order-details-modal');
            }
        );

        return apiCall;
    }

    public createContainerPlacingTask(): Observable<HttpResponse<void>> {
        const tasksService = this._serviceProvider.get(TASKS_SERVICE_IMPL);
        const apiCall = tasksService
            .createContainerPlacingTask(this._selectedOrderID)
            .pipe(share());

        apiCall.subscribe(
            (response) => {
                this.updateOrderDetails(this._selectedOrderID);
            },
            () => {
                const flashMessageService =
                    this._serviceProvider.get(FlashMessageService);
                flashMessageService.showStoredMessage('order-details-modal');
            }
        );

        return apiCall;
    }

    public createDeliveringTask(): Observable<HttpResponse<void>> {
        const tasksService = this._serviceProvider.get(TASKS_SERVICE_IMPL);
        const apiCall = tasksService
            .createDeliveringTask(this._selectedOrderID)
            .pipe(share());

        apiCall.subscribe(
            (reponse) => {
                this.updateOrderDetails(this._selectedOrderID);
            },
            () => {
                const flashMessageService =
                    this._serviceProvider.get(FlashMessageService);
                flashMessageService.showStoredMessage('order-details-modal');
            }
        );

        return apiCall;
    }

    public correctStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._ordersService.correctStockForOrder(correctionDto);
    }

    public correctInvoiceForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._ordersService.correctInvoiceForOrder(correctionDto);
    }

    public correctInvoiceAndStockForOrder(
        correctionDto: MarkupCorrectionDto
    ): Observable<HttpResponse<void>> {
        return this._ordersService.correctInvoiceAndStockForOrder(correctionDto);
    }

    public generateInvoiceJpl(orderID: number): Observable<HttpResponse<Blob>> {
        return this._ordersService.generateInvoiceJpl(orderID);
    }

    public getOrderHistory(orderID: number): Observable<OrderHistory[]> {
        return this._ordersService.getOrderHistory(orderID);
    }

    private updateOrderDetails(orderID) {
        this._ordersService
            .getOrderDetails(orderID)
            .subscribe((orderDetails) => this._orderDetails.next(orderDetails));
    }
}
