import { DeliveryTaskType } from '../types/delivery-task-type';
import { DeliveryTaskState } from '../types/delivery-task-state';

export class SetDeliveryTaskState {
    public orderID: number;
    public taskID: string;
    public providerShopID: number | null;
    public taskType?: DeliveryTaskType;
    public taskState: DeliveryTaskState;
    public newTaskState: DeliveryTaskState;
    public notes: string;
    public lat: number;
    public long: number;
}
