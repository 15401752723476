<div class="action-toolbar bg-secondary">
    <div class="row" *ngIf="states.isBarcodeReadingEnabled; else noBarcode">
        <div class="col col-2 text-center pr-0 mr-0" style="left: 8px"></div>
        <ng-container *ngTemplateOutlet="collapseButtons"></ng-container>
        <div class="col col-2 text-right pl-0 ml-0" style="right: 8px">
            <app-btn-action
                class="mr-1"
                [btnClass]="
                    'btn btn-primary btn-circle btn-lg btn-lateral add-new-product-button'
                "
                [iconClass]="'fa fa-search'"
                (click)="showProductIdentifyModal()"
                [disabled]="states.isCollectingForbidden"
            >
            </app-btn-action>
        </div>
    </div>
    <ng-template #noBarcode>
        <div class="row">
            <div class="col col-2 text-center pr-0 mr-0" style="left: 8px"></div>
            <ng-container *ngTemplateOutlet="collapseButtons"></ng-container>
            <div class="col col-2 text-right pl-0 ml-0" style="right: 8px">
                <app-btn-action
                    [btnClass]="
                        'btn btn-warning btn-circle btn-lg btn-lateral add-new-product-button'
                    "
                    [iconClass]="'fa fa-plus'"
                    (click)="showCreateItemModal()"
                    [disabled]="states.isCollectingForbidden"
                >
                </app-btn-action>
            </div>
        </div>
    </ng-template>
</div>

<ng-template #collapseButtons>
    <div
        class="align-middle col col-8 text-center pl-0 pr-0 ml-0 mr-0"
        style="line-height: 70px"
    >
        <button
            type="button"
            class="btn btn-sm btn-info ml-2 mr-2"
            (click)="hideAll(); show('items-to-pick')"
        >
            <span class="fa fa-search fa-lg"></span>
        </button>
        <button
            type="button"
            class="btn btn-sm btn-success ml-2 mr-2"
            (click)="hideAll(); show('collected-items')"
        >
            <span class="fa fa-cart-arrow-down fa-lg"></span>
        </button>
        <button
            type="button"
            class="btn btn-sm btn-warning ml-2 mr-2"
            (click)="hideAll(); show('replaced-items')"
        >
            <span class="fa fa-refresh fa-lg"></span>
        </button>
        <button
            type="button"
            class="btn btn-sm btn-danger ml-2 mr-2"
            (click)="hideAll(); show('missing-items')"
        >
            <span class="fa fa-exclamation-triangle fa-lg"></span>
        </button>
    </div>
</ng-template>
