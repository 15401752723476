<button
    *ngIf="
        !(
            onlineConsultation &&
            orderItem.orderReplacementItem?.quantityRequestedByUser === 0
        )
    "
    class="btn btn-danger"
    [disabled]="states.isCollectingForbidden"
    (click)="cancel()"
>
    <i class="fa fa-undo"></i>{{ 'common.cancel' | translate }}
</button>

<button
    *ngIf="
        onlineConsultation &&
        orderItem.orderReplacementItem?.quantityRequestedByUser === 0
    "
    class="btn alert-warning"
    [disabled]="states.isCollectingForbidden"
    (click)="removedFromBasket()"
>
    <i class="fa fa-arrow-up"></i>{{ 'collecting.removed-item-from-basket' | translate }}
</button>
