import { CollectingTaskListItem } from './collecting-task-list-item';
import { DeliveringTaskListItem } from './delivering-task-list-item';
import { ReturningTaskListItem } from './returning-task-list-item';
import { RefundingTaskListItem } from './refunding-task-list-item';
import { ContainerPlacingTaskListItem } from './container-placing-task-list-item';

export class TaskList {
    public collectingTasks: CollectingTaskListItem[];
    public containerPlacingTasks: ContainerPlacingTaskListItem[];
    public deliveringTasks: DeliveringTaskListItem[];
    public returningTasks: ReturningTaskListItem[];
    public refundingTasks: RefundingTaskListItem[];
    public supplierWorktimeIsActive: boolean;
    public maximumMultipickingTasks: number;
}
