import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import {
    IOfflineProductService,
    ModalService,
    OrderStateEventLog,
    EventType,
    ActionType,
    OfflineProduct,
    OFFLINEPRODUCT_SERVICE_IMPL,
    OfflineProductMapperService,
    OrderItem,
    OrderItemState,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
} from 'shared';
import { CollectingStatesService } from './collecting-states.service';
import { CollectingItemsService } from './collecting-items.service';
import { CollectingErrorsService } from './collecting-errors.service';
import { ProductIdentificationMode } from 'dm-src/types/product-identification-mode';
import { ProductIdentificationSource } from 'dm-src/types/product-identification-source';
import { FlashMessageService } from 'projects/shared/src/public-api';
import { IsWrappingService } from 'dm-src/services/is-wrapping/is-wrapping.service';

@Injectable({
    providedIn: 'root',
})
export class OfflineProductsService {
    private _isGTINProcessingInProgress = false;
    private _foundOfflineProducts: BehaviorSubject<OfflineProduct[]>;
    public isOrderItemFound = false;
    public foundOrderItems: OrderItem[] = [];
    public selectedOfflineProduct: OfflineProduct = null;
    public identifiedOfflineProductIndex = 0;

    public get foundOfflineProducts(): Observable<OfflineProduct[]> {
        return this._foundOfflineProducts.asObservable();
    }

    constructor(
        private _states: CollectingStatesService,
        private _collectingItemsService: CollectingItemsService,
        private _modalService: ModalService,
        private _errorsService: CollectingErrorsService,
        @Inject(OFFLINEPRODUCT_SERVICE_IMPL)
        private _offlineProductService: IOfflineProductService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService,
        private _mapper: OfflineProductMapperService,
        private _flashMessageService: FlashMessageService,
        private _isWrappingService: IsWrappingService
    ) {
        this._foundOfflineProducts = new BehaviorSubject<OfflineProduct[]>([]);
    }

    public getOfflineProduct(sku: string): void {
        this._states.productIdentificationMode = ProductIdentificationMode.ManualSKUCheck;
        this._offlineProductService.getBySKU(this._states.providerID, sku).subscribe(
            (response) => {
                this.onOfflineProductsFound(response.body);
            },
            (error) => this._errorsService.handleOfflineProductError(error.status)
        );
    }

    public processGtin(gtin: string): void {
        if (this._isGTINProcessingInProgress) {
            return;
        }

        this._isGTINProcessingInProgress = true;

        this._states.productIdentificationMode = ProductIdentificationMode.BarcodeRead;

        const foundOrderItem = this._collectingItemsService.findOrderItemByGTIN(gtin);
        const isOfflineProductCheckNeeded = !(
            foundOrderItem !== undefined && !this._states.hasMarkupPayment
        );

        if (!isOfflineProductCheckNeeded) {
            if (foundOrderItem.state === OrderItemState.Replaced) {
                this._collectingItemsService.replaceOrderItem(foundOrderItem);
            } else {
                this._collectingItemsService.approveItem(foundOrderItem);
            }
            this._isGTINProcessingInProgress = false;
        } else {
            this._offlineProductService
                .getByGTIN(this._states.providerID, gtin)
                .subscribe(
                    (response) => {
                        this.onOfflineProductsFound([response.body]);
                    },
                    (error) => {
                        if (
                            this._isWrappingService.isWrapping() ||
                            window.ReactNativeWebView
                        ) {
                            this._errorsService.handleOfflineProductError(error.status);
                        } else {
                            if (error.status !== 404) {
                                this._errorsService.handleOfflineProductError(
                                    error.status
                                );
                            }
                        }
                    }
                )
                .add(() => {
                    this._isGTINProcessingInProgress = false;
                });
        }
    }

    public collectNewOrderItem(): void {
        this._collectingItemsService
            .checkIfOrderModificationIsBlocked(this._states.orderID)
            .subscribe((blocked) => {
                if (blocked) {
                    return;
                } else {
                    const createOrderItem =
                        this._mapper.mapOfflineProductToCreateOrderItem(
                            this._states.orderID,
                            this.selectedOfflineProduct
                        );
                    const isOriginalPriceCheckNeeded =
                        this.foundOrderItems.length > 0 &&
                        (!this._states.isWerwPriceForced ||
                            !this.selectedOfflineProduct.isWerwProduct);

                    if (isOriginalPriceCheckNeeded) {
                        const firstSimilarProduct = this.foundOrderItems.find(
                            (x) => x.unitPrice !== this.selectedOfflineProduct.markupPrice
                        );
                        if (firstSimilarProduct !== undefined) {
                            createOrderItem.unitPrice = firstSimilarProduct.unitPrice;
                        }
                    }
                    this._collectingItemsService.createOrderItem(createOrderItem, true);

                    var orderStateEventLog = new OrderStateEventLog();

                    orderStateEventLog.eventType = EventType.OrderItemStateChanged;
                    orderStateEventLog.actionType =
                        ActionType.AddOrderItemToBasketAsNewProduct;
                    orderStateEventLog.fromStateJson = null;
                    orderStateEventLog.toStateJson = JSON.stringify(createOrderItem);
                    orderStateEventLog.orderID = createOrderItem.orderID;
                    orderStateEventLog.orderCode = this._states.orderCode;

                    this._loggerClientService
                        .addOrderStateEventLog(orderStateEventLog)
                        .subscribe();
                }
            });
    }

    public onOfflineProductSelected(): void {
        this.foundOrderItems = this._collectingItemsService.findOrderItemsBySKU(
            this.selectedOfflineProduct.sku
        );
    }

    public onOfflineProductsFound(offlineProducts: OfflineProduct[]): void {
        this.foundOrderItems = [];
        offlineProducts.forEach((offlineProduct) => {
            this.foundOrderItems = this.foundOrderItems.concat(
                this._collectingItemsService.findOrderItemsBySKU(offlineProduct.sku)
            );
        });

        this._foundOfflineProducts.next(offlineProducts);

        if (
            (this.foundOrderItems.length === 0 && offlineProducts.length === 1) ||
            (this.foundOrderItems.filter((x) => x.orderID === this._states.orderID)
                .length === 0 &&
                this._states.productIdentificationSource ===
                    ProductIdentificationSource.Replacement)
        ) {
            this.selectedOfflineProduct = offlineProducts[0];
            if (
                this._states.productIdentificationSource ===
                ProductIdentificationSource.Replacement
            ) {
                const orderItem = this.getOrderItemFromOfflineProduct(
                    this.selectedOfflineProduct
                );
                this._collectingItemsService.replaceOrderItem(orderItem);
            } else {
                this._states.setBarcodeReaderModalVisible(false);
                this._modalService.setModalVisibility(
                    true,
                    'invalid-offline-product-modal'
                );
            }
        } else {
            this._states.setBarcodeReaderModalVisible(false);
            this._modalService.setModalVisibility(true, 'found-product-chooser-modal');
        }
    }

    public resetFoundOrderItems(): void {
        this.foundOrderItems = [];
    }

    public onFoundProductAssigned(orderItem: OrderItem): void {
        const isReplacementProcess =
            orderItem?.state === OrderItemState.Replaced ||
            this._states.productIdentificationSource ===
                ProductIdentificationSource.Replacement;

        if (orderItem === null && !isReplacementProcess) {
            this._modalService.setModalVisibility(true, 'invalid-offline-product-modal');
            return;
        }

        if (isReplacementProcess) {
            if (this._states.replaceableOrderItem === null) {
                this._states.replaceableOrderItem = orderItem;
            }
            const replaceOrderItem = this.getOrderItemFromOfflineProduct(
                this.selectedOfflineProduct
            );
            replaceOrderItem.unitPrice = orderItem.unitPrice;
            if (
                this._states.isWerwPriceForced &&
                this.selectedOfflineProduct.isWerwProduct
            ) {
                replaceOrderItem.unitPrice = this.selectedOfflineProduct.markupPrice;
            }
            this._collectingItemsService.replaceOrderItem(replaceOrderItem);
        } else {
            if (
                this._states.isWerwPriceForced &&
                this.selectedOfflineProduct.isWerwProduct
            ) {
                orderItem.unitPrice = this.selectedOfflineProduct.markupPrice;
            }

            orderItem.providerOfflineProductID =
                this.selectedOfflineProduct.providerOfflineProductID;

            this._collectingItemsService.approveItem(orderItem);

            var orderStateEventLog = new OrderStateEventLog();

            orderStateEventLog.eventType = EventType.OrderItemStateChanged;
            orderStateEventLog.actionType = ActionType.AddOrderItemToBasket;
            orderStateEventLog.fromStateJson = null;
            orderStateEventLog.toStateJson = JSON.stringify(orderItem);
            orderStateEventLog.orderID = this._states.orderID;
            orderStateEventLog.orderCode = this._states.orderCode;

            this._loggerClientService
                .addOrderStateEventLog(orderStateEventLog)
                .subscribe();
        }
    }

    private getOrderItemFromOfflineProduct(offlineProduct: OfflineProduct): OrderItem {
        const quantity = offlineProduct.isWerwProduct
            ? offlineProduct.quantity
            : this._states.replaceableOrderItem.quantity;
        return this._mapper.mapOfflineProductToOrderItem(
            offlineProduct,
            this._states.replaceableOrderItem.orderItemID,
            quantity
        );
    }
}
