import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrdersService } from 'dm-src/app/modules/orders/orders.service';

@Component({
    selector: 'app-deliverer-notes',
    templateUrl: './deliverer-notes.component.html',
    styleUrls: ['./deliverer-notes.component.scss'],
})
export class DelivererNotesComponent implements OnInit {
    @Input() public delivererNotes: string;
    @Input() public orderID: number;
    @Input() public isVisible: boolean;

    public isEditMode = false;
    public isSubmitted = false;

    public delivererNotesForm: UntypedFormGroup;

    public get fields() {
        return this.delivererNotesForm.controls;
    }

    constructor(
        private _formBuilder: UntypedFormBuilder,
        private _ordersService: OrdersService
    ) {}

    ngOnInit(): void {
        this.delivererNotesForm = this._formBuilder.group({
            delivererNotes: [this.delivererNotes],
        });
    }

    public updateDelivererNotes() {
        this.isSubmitted = true;

        if (this.delivererNotesForm.invalid) {
            return false;
        }

        this._ordersService
            .updateDelivererNotes(this.fields.delivererNotes.value, this.orderID)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.delivererNotes = this.fields.delivererNotes.value;
                    this.isEditMode = false;
                }
            });
    }
}
