import { Component, Input, OnInit, Inject } from '@angular/core';
import {
    OrderItem,
    ILoggerClientService,
    LOGGER_CLIENT_SERVICE_IMPL,
    EventType,
    ActionType,
    OrderStateEventLog,
} from 'shared';
import { CollectingItemsService } from 'dm-src/app/modules/collecting/collecting-items.service';
import { CollectingStatesService } from '../../../collecting-states.service';

@Component({
    selector: '[app-collected-items-buttons]',
    templateUrl: './collected-items-buttons.component.html',
    styleUrls: ['./collected-items-buttons.component.scss'],
})
export class CollectedItemsButtonsComponent implements OnInit {
    @Input() orderItem: OrderItem;
    public onlineConsultation = false;

    constructor(
        private _orderItemsService: CollectingItemsService,
        public states: CollectingStatesService,
        @Inject(LOGGER_CLIENT_SERVICE_IMPL)
        private _loggerClientService: ILoggerClientService
    ) {}

    ngOnInit() {
        if (this.states.multiCollectingStates?.length > 1) {
            let multiCollectingState = this.states.multiCollectingStates.find(
                (x) => x.orderID == this.orderItem.orderID
            );
            this.onlineConsultation = multiCollectingState.onlineConsultation;
        } else {
            this.onlineConsultation = this.states.onlineConsultation;
        }
    }

    public cancel() {
        var orderStateEventLog = new OrderStateEventLog();

        orderStateEventLog.eventType = EventType.OrderItemStateChanged;
        orderStateEventLog.actionType = ActionType.CollectedOrderItemCancelButtonClicked;
        orderStateEventLog.fromStateJson = JSON.stringify(this.orderItem);
        orderStateEventLog.toStateJson = null;
        orderStateEventLog.orderID = this.orderItem.orderID;
        orderStateEventLog.orderCode = this.orderItem.orderCode;

        this._loggerClientService.addOrderStateEventLog(orderStateEventLog).subscribe();

        this._orderItemsService.removeItemFromCollected(this.orderItem);
    }

    public removedFromBasket() {
        this._orderItemsService.removeItemFromBasket(this.orderItem);
    }
}
